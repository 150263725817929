<template>
  <div class="autohaus">
    <Brand />
    <BackBtn
        v-if="route.query.ifa !== '1'"
        @click="back()"
        title="Zur Welt-Übersicht" />
    <div class="cols">
      <div class="col">
        <div>
          <span class="id" v-text="1" />
          <h1>Sales</h1>
          <button
              class="btn btn-primary"
              @click="goToSales()">
            Mehr erfahren
          </button>
          <Edges />
        </div>
      </div>
      <div class="col">
        <div>
          <span class="id" v-text="2" />
          <h1>Aftersales</h1>
          <button
              class="btn btn-primary"
              @click="goToAftersales()">
            Mehr erfahren
          </button>
          <Edges />
        </div>
      </div>
      <div class="col" v-if="route.query.ifa === '1'">
        <div>
          <span class="id" v-text="3" />
          <h1>Innovationen</h1>
          <button
              class="btn btn-primary"
              @click="goToInnovations()">
            Mehr erfahren
          </button>
          <Edges />
        </div>
      </div>
    </div>
    <svg
        viewBox="0 0 825 103.6"
        class="footer">
      <polygon class="st0" points="-5.5,31 234.4,96.5 836.4,29.8 836.4,116.3 -5.5,117.4" />
      <polygon class="st1" points="826.8,75.7 836.4,75.7 836.4,9.2 234.4,55.9 -5.5,10.4 -5.5,76.8 7.9,76.8 109,224.3 286,287.3 573,217.3 856,313.3" />
      <polygon class="st1" points="-39.3,218.8 -48.9,218.8 -48.9,285.3 553.1,238.6 793,284.1 793,217.7 779.6,217.7 678.5,70.2 501.5,7.2 214.5,77.2 -68.5,-18.8" />
      <polygon class="st1" points="-90,-2.9 609,93.1 828,45.1 914,58.1 865,167.1 530,173.1 366,210.1 23,71.1" />
    </svg>
    <h1 class="order">
      Autohaus
      <br><small>Wählen Sie einen Bereich!</small>
    </h1>
  </div>
</template>


<script>
import { getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import Brand from '@/components/Brand'
import BackBtn from '@/components/BackBtn'
import Edges from '@/components/Edges'

export default {
  name: 'Autohaus',

  components: {
    Brand,
    BackBtn,
    Edges
  },

  setup () {
    const instance = getCurrentInstance()
    const emitter  = instance.appContext.config.globalProperties.emitter
    const router   = useRouter()
    const route    = useRoute()

    const back = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Start',
        params: {
          slide: 6
        },
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    const goToSales = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Sales',
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    const goToAftersales = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Aftersales',
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    const goToInnovations = () => {
      emitter.emit('play-btn-sound')
      router.push({
        name: 'Innovations',
        query: {
          processBack: route.query.processBack,
          products: route.query.products,
          ifa: route.query.ifa
        }
      })
    }

    return {
      back,
      route,
      goToSales,
      goToAftersales,
      goToInnovations
    }
  }
}
</script>


<style lang="scss" scoped>
.autohaus {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: url('/static/img/bg-werkstatt.jpg') no-repeat center center;
  background-size: cover;

  svg.footer {
    position: absolute;
    bottom: 0;

    .st0{
      fill: #5b6e7f;
      opacity: 1;
    }

    .st1{
      opacity: 0.25;
      fill: #5b6e7f;
    }
  }

  .order {
    position: fixed;
    z-index: 10;
    left: 1.5rem;
    bottom: 3.5rem;
    color: #fff;
    font-weight: 600;
    font-size: 2.75rem;
    line-height: 0.5em;

    small {
      font-weight: 700;
      font-size: 1rem;
      text-transform: uppercase;
      color: #a0cbdf;
    }
  }

  .cols {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(180deg, rgba($color: #fff, $alpha: 0.25) 0%, rgba($color: #c7ddf0, $alpha: 0.85));

    > h1 {
      width: 100%;
      margin: auto 0 5rem 0;
      text-align: center;
      font-size: 2.75rem;
    }

    .col {
      margin: auto;
      text-align: center;
      position: relative;
      z-index: 10;
      width: 100%;
      margin-left: 3rem;

      &:last-child {
        margin-right: 3rem;
      }

      > div {
        padding: 3rem;
        display: flex;
        flex-direction: column;
        position: relative;
        border: 1px solid rgba($color: #000, $alpha: 0.15);

        .id {
          position: absolute;
          font-family: 'Navada Outline', sans-serif;
          font-size: 8rem;
          height: 8rem;
          line-height: 1em;
          left: 10%;
          top: -25%;
          color: rgba($color: #000, $alpha: 0.2);
          pointer-events: none;
        }

        h1 {
          margin: 0 0 2rem 0;
          font-size: 2.5rem;
          line-height: 1em;
          font-weight: 700;
        }
      }
    }
  }
}
</style>
